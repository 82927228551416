export default {
  'label/title': 'Meu plano',
  'label/will_cancel/text_line_1': 'Sua assinatura vai ser cancelada dia',
  'label/will_cancel/text_line_2': 'Você solicitou o cancelamento de sua assinatura.',
  'label/will_cancel/text_line_3': 'Se deseja assinar novamente, entre em contato com a nossa equipe.',
  'label/cc_past_due/text_line_1': 'Não conseguimos renovar sua assinatura no dia',
  'label/cc_past_due/text_line_2': 'Atualize seu cartão de crédito ou entre em contato com a nossa equipe.',
  'label/boleto_active/text_line_1': 'Sua assinatura vence dia {0} e ainda não foi identificado o pagamento.',
  'label/boleto_active/text_line_2': 'O Boleto de valor R$ {0} vencerá no dia {1}.',
  'label/boleto_active/text_line_3': 'Caso você já tenha pago esse boleto, por favor, desconsidere essa mensagem. Seu pagamento deve ser confirmado em até 3 dias úteis.',
  'label/payment_method': 'Forma de Pagamento',
  'label/credit_card': 'Cartão de Crédito',
  'label/boleto': 'Boleto ou PIX',
  'label/special': 'Especial',
  'label/inactive': 'Inativo',
  'label/sms_credits': 'Credito restante',
  'label/summary_text': 'Plano contratado em {0} com vencimento todo dia {1}.',
  'label/summary_text_year': 'Plano contratado em {0} com vencimento anual no dia {1}.',
  'action/talk_support': 'Falar com Suporte',
  'action/updated_credit_card': 'Atualizar Cartão',
  'action/cancel_sms_plan': 'Cancelar Plano de SMS',
  'action/request_sms_plan': 'Contratar Plano de SMS',
  'action/change_plan': 'Alterar Plano',
  'action/change': 'Gerenciar Pagamentos',
  'action/pay': 'Pagar',
  'action/cancel_plan': 'Cancelar Assinatura',
  'action/subscribe_again': 'Assinar Novamente',
  // V2
  'label/ios_msg_title': 'Você assinou o Doutore pelo iOS',
  'label/ios_msg_body_line_1': 'Sua assinatura será renovada automaticamente todo o mês.',
  'label/ios_msg_body_line_2': 'Se você quiser cancelar a assinatura do Doutore, você precisa ir no seu iOS em Ajustes > iTunes e App Store > Assinaturas.',
  'label/ios_msg_body_line_3': 'Infelizmente, nós não conseguimos cancelar a assinatura por você, você precisa fazer conforme as instruções acima.',
  'label/need_help': 'Precisa de Ajuda?',

  'label/boleto_active_expired_charge/title': 'Sua assinatura venceu dia {0}',
  'label/boleto_active_expired_charge/text_line_1': 'Não recebemos o pagamento do boleto com o valor {0}, com vencimento no dia {1}.',
  'label/boleto_active_expired_charge/text_line_2': 'Emita um novo boleto e pague-o imediatamente. Sua conta será bloqueada por falta de pagamento.',
  'label/boleto_active_expired_charge/text_line_3': 'Caso você já tenha pago esse boleto, desconsidere essa mensagem. Nós receberemos a confirmação do seu pagamento em 1 dia útil.',

  'label/boleto_past_due_expired_charge/title': 'Sua assinatura venceu dia {0}',
  'label/boleto_past_due_expired_charge/text_line_1': 'Não recebemos o pagamento do boleto com vencimento no dia {0}.',
  'label/boleto_past_due_expired_charge/text_line_2': 'Emita um novo boleto e pague-o imediatamente. Sua conta será bloqueada por falta de pagamento.',
  'label/boleto_past_due_expired_charge/text_line_3': 'Caso você já tenha pago esse boleto, desconsidere essa mensagem. Nós receberemos a confirmação do seu pagamento em 1 dia útil.',

  'label/boleto_past_due/title': 'Sua assinatura venceu dia {0} e não foi identificado o pagamento',
  'label/boleto_past_due/text_line_1': 'Seu boleto no valor de {0} vencerá no dia {1}.',
  'label/boleto_past_due/text_line_2': 'Caso você já tenha pago esse boleto, por favor, desconsidere essa mensagem. Seu pagamento deve ser confirmado em até 3 dias úteis.',

  'label/boleto_active_without_charge/title': 'Without charge text here XD',
  'label/boleto_active_without_charge/text_line_1': 'lorem impsum.',
  'label/boleto_active_without_charge/text_line_2': 'lorem impsum lorem impsum',

  'label/expired/text_line_1': 'Não recebemos o pagamento de sua assinatura.',
  'label/expired/text_line_2': 'Sua assinatura venceu no dia {0} e não recebemos o pagamento. Efetue o pagamento para desbloquear sua conta.',

  'label/canceled/text_line_1': 'Sua conta no Doutore está cancelada.',
  'label/canceled/text_line_2': 'Você gostaria de voltar a utilizar?',

  'action/request_new_boleto': 'Gerar Novo Boleto',
  'action/view_boleto': 'Ver Boleto',
  'label/plan': 'Plano',

  'label/sms_title': 'SMS',
  'label/sms_credits_2': 'Saldo Restante de SMS',
  'action/details': 'Detalhes',

  'label/boleto_message_days': 'Você receberá um boleto por email, sempre 5 dias antes do vencimento',
  'action/fix_payment_info_problems_label': 'DADOS DE PAGAMENTO',
  'action/fix_payment_info_problems': 'Seus dados de pagamento estão incompletos',
  'action/fix_payment_info_problems_edit': 'Editar',
};
