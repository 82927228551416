// @flow
import React from 'react';
import { isEmail } from 'validator';
import {
  get, includes, without,
  uniq, concat, endsWith, isEqual,
} from 'lodash';
import { isValidBrPhone } from '@helpers/validator/rules/testers';
import {
  Icon, Button, Input,
  Modal, InputMask,
} from '@elements';
import type { FormElementData } from '@types';
import { handleSubmitEvent } from '@helpers/helpers';


type State = {
  errors: Array<string>,
  formData: {
    name: string,
    mobilePhone: string,
    homePhone: string,
    email: string,
    recommendedBy: string,
    insurancePlan: string,
  }
};

type Props = {
  setModal: Function,
  onCreatePatient: Function,
  searchValue: string,
  isSavingPatient: boolean,
  previousModal: any,
  clinic: any,
  _: Function,
};

export default class NewPatientForm extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      errors: [],
      formData: {
        name: this.props.searchValue || '',
        mobilePhone: '',
        homePhone: '',
        email: '',
        recommendedBy: '',
        insurancePlan: '',
      },
    };
  }

  componentDidMount() {
    handleSubmitEvent('#newPatientFormOnAppoitment', this.onCreatePatient);
  }

  shouldComponentUpdate(nextProps: Props, nextState: State) {
    return !isEqual(this.props, nextProps) || !isEqual(this.state, nextState);
  }

  render() {
    const { errors, formData } = this.state;
    const { isSavingPatient, clinic, _ } = this.props;

    const homePhone = get(formData, 'homePhone', '');
    const mobilePhone = get(formData, 'mobilePhone', '');

    return (
      <Modal id="patientModal" className="secondaryModal">
        <div className="modalHeader actionHeader">
          <Button className="backButton" onClick={this.handleClose}>
            <Icon iconName="arrow-left" className="icon" />
          </Button>
          <span>{_('label/patient_form/title')}</span>
        </div>
        <div className="modalGrid">
          <div className="modalContainer">
            <div className="modalContent">
              <div id="newPatientFormOnAppoitment" className="form">
                <Input
                  type="text"
                  name="name"
                  label={_('label/patient_form/name', null, s => `${s}:`)}
                  required
                  value={get(formData, 'name', '')}
                  error={includes(errors, 'name') ? 'Required' : ''}
                  onChange={this.handleFormChange}
                  autoComplete="off"
                  autofocus
                />

                <InputMask
                  type="phone"
                  name="mobilePhone"
                  label={_('label/patient_form/mobile_phone', null, s => `${s}:`)}
                  value={mobilePhone}
                  onChange={this.handleFormChange}
                  error={includes(errors, 'mobilePhone') ? 'Telefone Inválido' : ''}
                  languageOverride={clinic.international}
                />

                <InputMask
                  type="phone"
                  name="homePhone"
                  label={_('label/patient_form/home_phone', null, s => `${s}:`)}
                  value={homePhone}
                  onChange={this.handleFormChange}
                  error={includes(errors, 'homePhone') ? 'Telefone Inválido' : ''}
                  languageOverride={clinic.international}
                />

                <Input
                  type="text"
                  name="email"
                  label={_('label/patient_form/email', null, s => `${s}:`)}
                  value={get(formData, 'email', '')}
                  error={includes(errors, 'email') ? 'E-mail Inválido' : ''}
                  onChange={this.handleFormChange}
                />

                <Input
                  type="text"
                  name="recommendedBy"
                  label={_('label/patient_form/recommended_by', null, s => `${s}:`)}
                  value={get(formData, 'recommendedBy', '')}
                  onChange={this.handleFormChange}
                />

                <Input
                  type="text"
                  name="insurance_company"
                  label={_('label/patient_form/insurance_plan', null, s => `${s}:`)}
                  value={get(formData, 'insurance_company', '')}
                  onChange={this.handleFormChange}
                />

              </div>
            </div>
          </div>
        </div>
        <div className="modalFooter">
          <Button
            disabled={isSavingPatient}
            text="Cancelar"
            onClick={this.handleClose}
          />

          <Button
            isLoading={isSavingPatient}
            className="primary normal-spinner"
            text="Salvar"
            onClick={this.onCreatePatient}
          />
        </div>
      </Modal>
    );
  }

  handleFormChange = (data: FormElementData) => {
    const { name, value } = data;
    const { errors } = this.state;
    const formData: any = {
      ...this.state.formData,
      [name]: value,
    };

    let newErrors = [];
    if (name === 'name') {
      newErrors = value !== '' ? without(errors, name) : concat(errors, name);
    } else if (name === 'email' && typeof value === 'string') {
      newErrors = isEmail(value) ? without(errors, name) : concat(errors, name);
    } else if (name === 'mobilePhone' && String(value).length >= 10) {
      newErrors = isValidBrPhone.run(value, { allowEmpty: true }) ? without(errors, name) : concat(errors, name);
    } else if (name === 'homePhone' && String(value).length >= 10) {
      newErrors = isValidBrPhone.run(value, { allowEmpty: true }) ? without(errors, name) : concat(errors, name);
    }

    this.setState({
      formData,
      errors: newErrors,
    });
  };

  onCreatePatient = () => {
    const errors = [];
    if (!this.state.formData.name) {
      errors.push('name');
    }

    if (!isValidBrPhone.run(this.state.formData.mobilePhone, { allowEmpty: true })) {
      errors.push('mobilePhone');
    }

    if (!isValidBrPhone.run(this.state.formData.homePhone, { allowEmpty: true })) {
      errors.push('homePhone');
    }

    if (this.state.errors.length || errors.length) {
      this.setState({ errors: uniq(concat(this.state.errors, errors)) });
      return;
    }

    this.props.onCreatePatient(this.state.formData);
  };

  checkSpecialCharEnd = (text: string) => (
    endsWith(text, '/') || endsWith(text, '(') ||
    endsWith(text, ')') || endsWith(text, '.') ||
    endsWith(text, '-') || endsWith(text, ' ')
  );

  handleSpecialCharDelete = (e: Object) => {
    let endsWithSpecialChar = this.checkSpecialCharEnd(e.target.value);
    if (e.keyCode === 8) {
      while (endsWithSpecialChar) {
        e.target.value = e.target.value.slice(0, -1);
        endsWithSpecialChar = this.checkSpecialCharEnd(e.target.value);
      }
    }
  };

  handleClose = () => {
    if (this.props.previousModal === 'PatientSelectModal') {
      this.props.setModal('PatientSelectModal');
    } else {
      this.props.setModal(null);
    }
  };
}
